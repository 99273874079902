import type { IUiIncentiveDataProviderV3 } from '@amply-app/sdk/dist/esm/types/contracts/UiIncentiveDataProviderV3';
import type { UseAllPoolsData } from '@ui/hooks/data';
import { SECONDS_PER_YEAR } from '@ui/hooks/data/constants';
import { Fraction } from 'bi-fraction';

export const calculateAPY = (
  item: IUiIncentiveDataProviderV3.RewardInfoStructOutput,
  tokenData: UseAllPoolsData,
  amplyPrice?: Fraction,
  tokenAmount?: Fraction,
  tokenPrice?: Fraction,
) => {
  const num =
    amplyPrice &&
    new Fraction(item[2])
      .shr(Number(item[6]))
      .mul(amplyPrice)
      .mul(SECONDS_PER_YEAR);
  const den =
    tokenAmount &&
    tokenPrice &&
    new Fraction(tokenAmount).shr(tokenData.decimals).mul(tokenPrice);
  return (
    num &&
    den &&
    num.div(den.isZero() ? Fraction.ONE : den).shr(tokenData.decimals)
  );
};
